@import '../../../../scss/theme-bootstrap';

.homepage-formatter {
  .content-block-large.bottom-border {
    padding-bottom: 75px;
  }
  .special-projects__section {
    @include breakpoint($large-up) {
      padding-top: 70px;
      padding-bottom: 50px;
    }
  }
  .special-projects__section:after {
    @include breakpoint($large-up) {
      margin-top: 105px;
    }
  }
}
